"use strict";
(() => {
    $("._dob-l10n").mask("00/00/0000");
    const $findAccountContainer = $("#find-account-form");
    const $findEmailContainer = $("#find-email-form");
    const $findPhoneContainer = $("#find-phone-form");
    const $verifyFindType = $("#verify-find-type");

    const addOrRemoveHiddenClass = ({
        container,
        isHidden = true,
    }: {
        container: JQuery<Element>;
        isHidden: boolean;
    }) => {
        if (isHidden) {
            container.addClass("hidden");
        } else {
            container.removeClass("hidden");
        }
    };

    const handleChange = () => {
        const loginTypeSelectVal = $verifyFindType.val();
        const isNotLoginType = (which: string) => loginTypeSelectVal !== which;
        addOrRemoveHiddenClass({
            container: $findAccountContainer,
            isHidden: isNotLoginType("account"),
        });
        addOrRemoveHiddenClass({
            container: $findEmailContainer,
            isHidden: isNotLoginType("email"),
        });
        addOrRemoveHiddenClass({
            container: $findPhoneContainer,
            isHidden: isNotLoginType("phone"),
        });
    };

    $verifyFindType.on("change", handleChange);
})();

export {};
